<template>
  <div class="searchLong">
    <el-select
      v-model="select"
      :loading="isLoading"
      remote
      filterable
      :remote-method="queryFromUser"
      placeholder="Введите адрес или координаты"
      size="small"
      @change="doAction"
    >
      <el-option
        v-for="(item, key) in options"
        :key="Date.now() + key"
        :label="item.value"
        :value="item"></el-option>
    </el-select>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'SearchLongField',
    props: {
    },
    data: function () {
      return {
        isLoading: false,
        select: null,
        options: []
      }
    },
    methods: {
      doAction(query) {
        this.$emit('doComponentAction', this.select)
      },
      queryFromUser (query) {
        this.options.splice(0, this.options.length)
        //сделать регулярное выражение на координаты 4326
        let itsWord = query.match(new RegExp('[А-Яа-я]', ''))
        if (itsWord) {
          this.findAddress(query)
          return
        }
        let coordinates = query.match(new RegExp('[0-9]{1,2}([\,\.]{1}[0-9]*)', 'g'))
        if (coordinates.length === 2) {
          coordinates = coordinates.map(x => x.replace(',', '.'))
          this.options.splice(0, 0, { value: coordinates[0] + ' ' +coordinates[1], data: { 'geo_lat': coordinates[0], 'geo_lon': coordinates[1] }})
        }
      },
      findAddress(query) {
        let me = this
        if (this.defaultValue) {
          query = this.defaultValue + ' ' + query
        }
        this.daData = axios.create()
        this.daData.interceptors.request.use(function (config) {
          config.headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Token 03fab14d0555d6f7a9ea39754dbdc4771b4c3a8a'
          }
          return config
        }, function (error) {
          return Promise.reject(error)
        })
        this.daData
          .post(
            `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
            { query: query },
            { hideNotification: true }
          )
          .then((response) => {
            me.options.splice(0, me.options.length)
            response.data.suggestions.forEach((item) => {
              me.options.splice(me.options.length, 0, item)
            })
          })
      }
    }
  }
</script>

<style scoped>
.searchLong {
  padding: 0px 7px;
}
</style>
