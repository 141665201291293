<template>
  <div ref=map-container class="map-container">
    <el-form-item
      prop="mapValidator"
      style="margin: 0;"
      :rules="[{ validator: inputValidator, trigger: 'blur', message: 'Невозможно сохранить некорректную геометрию' }]"
    ></el-form-item>
    <transition name="slide-fade">
      <tools-panel
        v-show="showToolsPanel"
        class="tools-panel"
        ref="tools_panel"
        :layers="layers"
        :expandLayersByDefault="expandLayersByDefault"
        :toolsPanelWidth="toolsPanelWidth"
        :showLayersOpacity="showLayersOpacity"
        :interactiveObjects="activeFeatures"
        :mobileMode="mobileMode"
        :map="map"
        :activeLayers="activeLayers"
        :is-fullscreen="isExpanded"
        :featureMetrics="featureMetrics"
        :activeLayersLength="activeLayersLength"
        @show-tools-panel="showHideLayersButton"
        @layer-select="onLayerSelect"
        @zoom-on-feature="zoomOnFeature"
        @delete-geometry="deleteGeometry"
        @change-layer-opacity="changeLayerOpacity"
        @highlight-features="highlightFeatures"
        @unhighlight-features="unhighlightFeatures"
        @export="exportFromToolsPanel"
        @open-card="openCardFromPanel"
        @save-geometry="saveGeometry"
        @apply-filter="applyDynamicFilter"
        @intersection-feature="intersectionFeature"
        @tools-panel-resized=setComponentSize
        @calculate-features="calculateFeatures"
        @focus-on-layer="focusOnLayer"
        @copy-geometry="copyGeometry"
      ></tools-panel>
    </transition>
    <div ref="street-view" id="street-view" :class="streetViewContainerClass"></div>
    <div
      tabindex="0"
      ref="map"
      :id="guid"
      :class="mapClass"
      @keyup.ctrl.67="copyActiveFeaturesGeoJSON"
      @keyup.ctrl.86="pasteGeoJSONInActiveLayer"
    ></div>
    <zoom class="zoom" @change="changeZoom" :mobileMode="mobileMode" :zoomValue="zoomValue"></zoom>
    <tools
      ref="tools"
      :settings="tools"
      :style="toolsTrStyle"
      :mobileMode="mobileMode"
      :toolsPosition="toolsPosition"
      :checkMarks="toolsCheckMarks"
      :activated="activeInteractiveType"
      :componentSize="componentSize"
      @drawing-geometry="drawingGeometry"
      @measurement="measurement"
      @layers="showHideLayersButton"
      @google-street-view="googleStreetView"
      @expand="expand"
      @position-on="showPositionOnWindow"
      @delete-features="deleteFeatures"
      @change-selection-type="changeSelectInteractionType"
      @print="print"
      @import-features="importFeatures"
      @export-features="exportFeatures"
      @search="search"
      @search-component="searchToolComponent"
    />
    <cadastr-object-panel
      :area="cadastrInfo.area"
      :building="cadastrInfo.building"
      v-if="showCadastrPanel"
      @close-cadastr-object-panel="closeCadastrPanel"
    ></cadastr-object-panel>
    <footer-panel
      ref="footerPanel"
      :coordinates="footerData.coordinates"
      :cs="footerData.targetCS"
      @change-target-cs="changeFooterTargetCS"
      v-if="!mobileMode && showMouseCoordinates"
      :userCSs="userCSs"
    ></footer-panel>
    <slot></slot>
    <import-features
      ref="import_features"
      :userCSs="userCSs"
      :importLayerGeometryField="importLayerGeometryField"
      @import-geojson-features="importGeoJSONFeatures"
      @import-wkt-string="importWKTString"
      ></import-features>
    <el-dialog
      title="Пересечение геометрий"
      top="10vh"
      :modal="false"
      :visible.sync="intersection.showWindow">
      {{intersection.message}}
      <div v-for="l in intersection.layers">{{ l }}</div>
    </el-dialog>
    <position-on
      :user-c-ss="userCSs"
      :address-settings="positionOnAddressSettings"
      ref="position-on-window"
      @position-on="positionOn"
    ></position-on>
    <create-geometry-by-vertices
      :map="map"
      :user-c-ss="userCSs"
      :layer="createGeometryByVertices.layer"
      :register-c-s="loadAndRegisterCS"
      ref="create-geometry-by-vertices"
      @geometry-added="refreshGeometryField"
      ></create-geometry-by-vertices>
  </div>
</template>

<script>
import MapManager from '@bingo_soft/mapmanager'
import PropertiesMixin from '@/components/InterfaceEditor/components/PropertiesMixin'
import Zoom from '@/components/InterfaceEditor/components/basic/Map/Zoom'
import { easeOut } from 'ol/easing.js'
import Tools from '@/components/InterfaceEditor/components/basic/Map/Tools/index'
import { NodeLayerType } from '@/components/InterfaceEditor/components/basic/Map/NodeLayerType'
import SourceType from '@bingo_soft/mapmanager/src/Domain/Model/Source/SourceType'
import * as OlProj from 'ol/proj'
import * as google from 'google-maps'
import openCard_mixin from '@/components/InterfaceEditor/components/openCard_mixin'
import ToolsPanel from '@/components/InterfaceEditor/components/basic/Map/ToolsPanel/index'
import FeatureCollection from '@bingo_soft/mapmanager/src/Domain/Model/Feature/FeatureCollection'
import PositionOn from './Tools/PositionOn/index'
import CreateGeometryByVertices from './Tools/createGeometryByVertices'
import { APIClient, HTTPMethod } from '@/core/infrastructure/api/APIClient'
import { DotNetSpatialServiceAPI } from '@/core/infrastructure/api/modules/DotNetSpatialServiceAPI'
import { RosreestrAPI } from '@/core/infrastructure/api/modules/RosreestrAPI'
import { MapEditorAPI } from '@/core/infrastructure/api/modules/MapEditorAPI'
import CadastrObjectPanel from './CadastrObjectPanel'
import FilterBuilder, { EComponentTypes } from '@/components/InterfaceEditor/components/utils'
import axios from 'axios'

import importStringWKTForm from './Tools/importFeatures/stringWKTForm'
import FooterPanel from './footerPanel'
import ImportFeatures from './Tools/importFeatures'

import CursorType from '@bingo_soft/mapmanager/src/Domain/Model/Map/CursorType'
import EventType from '@bingo_soft/mapmanager/src/Domain/Model/EventHandlerCollection/EventType'
import InteractionType from '@bingo_soft/mapmanager/src/Domain/Model/Interaction/InteractionType'
import ExportType from '@bingo_soft/mapmanager/src/Domain/Model/Map/ExportType'
import GeometryFormat from '@bingo_soft/mapmanager/src/Domain/Model/Feature/GeometryFormat'
const INTERACTION_MODES = {
  MEASURE: {
    LINE: 'distance',
    SQUARE: 'area'
  },
  EDIT: 'EditByVertices',
  DRAWING: {
    POINT: 'Point',
    LINESTRING: 'LineString',
    POLYGON: 'Polygon'
  },
  SELECT: {
    POINT: 'singleclick',
    RECTANGLE: 'rectangle',
    POLYGON: 'polygon',
    INTERSECTION: 'intersection',
    ROSREESTR: 'rosreestr'
  },
  DEFINE_ADDRESS: 'defineAddress'
}
const INTERACTION_MODES_OPTIONS = [
  { id: INTERACTION_MODES.MEASURE.LINE, name: 'Измерение дистанции' },
  { id: INTERACTION_MODES.MEASURE.SQUARE, name: 'Измерение площади' },
  { id: INTERACTION_MODES.EDIT, name: 'Редактирование по вершинам' },
  { id: INTERACTION_MODES.DRAWING.POINT, name: 'Рисование точки' },
  { id: INTERACTION_MODES.DRAWING.LINESTRING, name: 'Рисование линии' },
  { id: INTERACTION_MODES.DRAWING.POLYGON, name: 'Рисование полигона' },
  { id: INTERACTION_MODES.SELECT.POINT, name: 'Выделение точкой' },
  { id: INTERACTION_MODES.SELECT.RECTANGLE, name: 'Выделение прямоугольником' },
  { id: INTERACTION_MODES.SELECT.POLYGON, name: 'Выделение полигоном' },
  { id: INTERACTION_MODES.SELECT.INTERSECTION, name: 'Поиск пересечений' },
  { id: INTERACTION_MODES.SELECT.ROSREESTR, name: 'Сведения росреестра' },
  { id: INTERACTION_MODES.DEFINE_ADDRESS, name: 'Определение адреса' },
]

export default {
  name: 'map',
  components: { CadastrObjectPanel, Tools, Zoom, ToolsPanel, FooterPanel, ImportFeatures, PositionOn, CreateGeometryByVertices },
  mixins: [PropertiesMixin, openCard_mixin],
  inject: {
    isEditor: {
      default: () => false
    },
    getInterfaceEditorVersion: {
      default: () => () => 1
    },
    getCard: {
      default: () => {}
    }
  },
  props: {
    defaultCenter: {
      type: String,
      description: 'Координаты центра (через ,)'
    },
    defaultZoom: {
      type: Number,
      description: 'Зум по умолчанию',
      default: 2
    },
    declaredCoordinateSystemId: {
      type: Number,
      description: 'Система координат',
      hidden: true,
      default: 3857
    },
    layers: {
      type: Array,
      editor: 'MapLayers',
      description: 'Слои'
    },
    toolsPanelWidth: {
      type: Number,
      description: 'Ширина панели слоев',
      default: 400
    },
    showLayersOpacity: {
      type: Boolean,
      description: 'Показывать прозрачность слоёв',
      default: false
    },
    showLayersByDefault: {
      type: Boolean,
      description: 'Показывать дерево слоев при открытии',
      default: false
    },
    expandLayersByDefault: {
      type: Boolean,
      description: 'Раскрывать дерево слоев при открытии',
      default: false
    },
    tools: {
      type: Object,
      description: 'Инструменты',
      default: () => {
        return {}
      },
      editor: 'MapTools'
    },
    coordinateSystems: {
      type: Object,
      description: 'Часто используемые системы координат',
      default: () => {
        return { items: [] }
      },
      editor: 'MapCoordinateSystems'
    },
    mobileMode: {
      type: Boolean,
      description: 'Мобильное представление',
      default: false
    },
    showMouseCoordinates: {
      type: Boolean,
      description: 'Показывать координаты мыши',
      default: false
    },
    hideToolsPanelAfterExpand: {
      type: Boolean,
      description: 'Скрывать панель слоев после выхода из полноэкранного режима',
      default: false
    },
    showScalelineControl: {
      type: Boolean,
      description: 'Показывать численный масштаб',
      default: false
    },
    baseLayerUseProxy: {
      type: Boolean,
      description: 'Использовать прокси для для подложки OSM',
      default: false
    },
    hideOSMLayer: {
      type: Boolean,
      description: 'Скрыть стандартную подложку OSM',
      default: false
    },
    toolsPosition: {
      editor: 'Select',
      description: 'Позиция инструментов',
      default: 'tr',
      options: {
        multiple: false,
        options: [
          { id: 'tr', name: 'Сверху справа' },
          { id: 'tl', name: 'Сверху слева' },
          { id: 'br', name: 'Снизу справа' },
          { id: 'bl', name: 'Снизу слева' }
        ]
      }
    },
    activeInteractiveByDefault: {
      editor: 'Select',
      description: 'Активный интерактив по умолчанию',
      default: INTERACTION_MODES.SELECT.POINT,
      options: {
        multiple: false,
        options: INTERACTION_MODES_OPTIONS
      }
    },
    isSelectInteractionPin: {
      type: Boolean,
      description: 'Использовать прокол для интерактива "Выделение"',
      default: true
    },
    featureMetrics: {
      type: Object,
      description: 'Отображение метрик геометрии',
      default: function () {
        return {
          units: 'meters',
          enable: false,
          enableSwitch: false,
          numbersAfterDot: 2
        }
      },
      editor: 'FeatureMetrics'
    }
  },
  data () {
    return {
      guid: null,
      map: undefined,
      showToolsPanel: false,
      activeLayers: [],
      isGoogleStreetViewActive: false,
      googlePanorama: null,
      google: null,
      googleMapsCoordinateSystemId: 4326,
      isExpanded: false,
      expandedSettings: {
        notExpandedStyle: null,
        containers: [],
        dashboard: { dom: null, defaultWidth: null },
        tabSelectionHeight: 0,
        tab: null,
        v2: {}
      },
      resizeObserver: false,
      showCardsPanel: false,
      cards: [],
      isLoading: false,
      position: {
        coordinates: {
          x: 0,
          y: 0
        },
        address: '',
        show_marker: true
      },
      activeFeatures: [],
      selectedObjects: [],
      showCadastrPanel: false,
      cadastrInfo: {
        area: {},
        building: {}
      },
      isLoadingToolsPanel: false,
      footerData: {
        coordinates: [0, 0],
        targetCS: this.declaredCoordinateSystemId
      },
      currentInteraction: null,
      toolsCheckMarks: {},
      isEditVerticesActive: false,
      userCSs: [],
      activeInteractiveType: [],
      paintBindingRadius: { enable: false, radius: 0 },
      zoomValue: this.defaultZoom,
      snapMode: false,
      fieldSourceLayers: {},
      importLayerGeometryField: null,
      intersection: {
        showWindow: false,
        message: '',
        layers: []
      },
      componentSize: {
        width: null,
        height: null,
        toolsPanelWidth: this.realToolsPanelWidth
      },
      realToolsPanelWidth: this.toolsPanelWidth,
      positionOnAddressSettings: { addressPrefix: '', valueByDefault: ''},
      createGeometryByVertices: {
        layer: null
      }
    }
  },
  computed: {
    model () {
      return this.getModel()
    },
    streetViewContainerClass () {
      if (this.isGoogleStreetViewActive) {
        return 'streetViewContainer'
      }
      return 'streetViewContainerCollapsed'
    },
    mapClass () {
      if (this.isGoogleStreetViewActive) {
        return 'mapCollapsed'
      }
      return 'map'
    },
    toolsTrStyle () {
      if (this.isEditor() && this.toolsPosition === 'tr') {
        return 'top: 30px;'
      }
      return ''
    },
    activeLayersLength () {
      return this.activeLayers.length
    }
  },
  provide () {
    return {
      dashboardAndUserCSs: () => { return this.userCSs }
    }
  },
  watch: {
    model: {
      handler () {
        const activeLayersAtCurrentTime = this.activeLayers.map((item) => {
          return {
            filters: item.filters,
            lastFilters: item.lastFilters,
            guid: item.guid,
            layerData: item.layerData,
            name: item.name
          }
        })
        activeLayersAtCurrentTime.forEach((item) => {
          if (item.filters.length === 0) {
            return false
          }
          const newFilters = this.buildLayerFilters(item.filters)
          if (JSON.stringify(newFilters) !== JSON.stringify(item.lastFilters)) {
            this.hideLayer(item.guid)
            this.showLayer(item.guid, {
              name: item.name,
              properties: item.layerData
            })
          }
        })
      },
      deep: true
    },
    currentInteraction: {
      handler: function (newValue, oldValue) {
        this.removeInteraction(oldValue)
        this.applyInteraction(newValue)
      }
    }
  },
  beforeMount () {
    this.guid = this.generateGuid()
  },
  async mounted () {
    if (!this.isEditor()) {
      for (let i = 0; i < this.layers.length; i++) {
        this.layers.splice(i, 1, this.separateLayers(this.layers[i]))
      }
    }
    const { x, y } = this.getDefaultCenter()
    let optsMap = {
      base_layer: 'osm',
      declared_coordinate_system_id: this.declaredCoordinateSystemId,
      center: {
        x: x,
        y: y,
        declared_coordinate_system_id: 4326
      },
      zoom: this.defaultZoom,
      controls: []
    }
    if (this.hideOSMLayer) {
      optsMap.base_layer = null
    }
    if (this.baseLayerUseProxy) {
      optsMap.base_layer_use_proxy = true
    }
    if (this.showScalelineControl) {
      optsMap.controls.push('scaleline')
    }
    this.map = MapManager.createMap(this.guid, optsMap)
    this.showToolsPanel = this.showLayersByDefault
    this.setComponentSize()
    const me = this
    this.repaintMap()
    this.applyShowMouseCoordinatesInteractive(this.showMouseCoordinates)
    await this.fillMouseCoordinates(this.$store.getters['Authorization/userId'], this.coordinateSystems.items)
    if (!this.isEditor()) {
      this.currentInteraction = this.activeInteractiveByDefault
    }
    MapManager.setZoomCallback(this.map, (zoom) => {
      me.zoomValue = Math.round(zoom * 10) / 10
    })
    let observer = new IntersectionObserver((entries, observer) => {
      this.repaintMap()
    })
    observer.observe(this.$el)
  },
  methods: {
    async pasteGeoJSONInActiveLayer () {
      //обрабочик ctrl+v
      const gjson = await navigator.clipboard.readText().then((text) => {
        return text
      })

      const activeLayerData = this.getActiveLayer(true)
      /*
      this.$confirm(
        'Вы уверены что хотите вставить геометрию в слой "' + activeLayerData.name + '"'
      ).then(() => {*/
        try {
          const fc = MapManager.createFeatureCollectionFromGeoJSON(
            gjson,
            activeLayerData.layerData.source.nativeCoordinateSystemId,
            this.declaredCoordinateSystemId
          )

          MapManager.addFeatures(this.map, activeLayerData.layer, fc)
          MapManager.fitFeatures(this.map, fc)
          this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()
          this.$message({
            message: `Вставка геометрии прошла успешно`,
            type: 'success'
          })
        } catch (e) {
          console.log(e)
          this.showError('Не удалось вставить геометрию')
        }
      //})
    },
    copyActiveFeaturesGeoJSON () {
      //обработчик ctrl+c
      if (this.activeFeatures.length !== 1) {
        this.showError('Сейчас копировать можно только 1 выбранную геометрию')
        return
      }

      //let result = ''
      const geojson = MapManager.getGeometryAsText(
        this.activeFeatures[0].item,
        GeometryFormat.GeoJSON,
        this.activeFeatures[0].nativeCoordinateSystemId
      )
      //MapManager.
      this.$copyText(geojson)
      this.showSuccess('Геометрия скопирована')
    },
    copyGeometry (a) {
      const guid = a[1]
      const feature = a[0]
      const layer = this.activeLayers.find(l => l.guid === guid)
      this.$confirm(
        'Вы уверены что хотите скопировать выбранную геометрию в "' + layer.name + '"', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then((confirm) => {
        if (guid === feature.layerProperties.guid) {
          this.showError('Копирование геометрии внутри слоя невозможно')
          return
        }
        try {
          MapManager.addFeatures(this.map, layer.layer, new FeatureCollection([feature.feature.feature], layer.layer))
          this.refreshGeometryField('Успешно скопировано', true)
        } catch (e) {
          console.log(e)
        }
      })

    },
    repaintMap () {
      this.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(this.map), 0)
      })
    },
    searchToolComponent (address) {
      this.positionOn({x: address.data.geo_lon, y: address.data.geo_lat}, 4326)
    },
    getActiveLayerByGuid (guid) {
      return this.activeLayers.find(x => x.guid === guid)
    },
    focusOnLayer (layer) {
      //let mapLayer = this.activeLayers.find((item) => { return item.guid === layer.guid })
      this.fitWithDefaultZoom(this.getActiveLayerByGuid(layer.guid).layer)
      // MapManager.fitLayer(this.map, this.getActiveLayerByGuid(layer.guid).layer)
    },
    async calculateFeatures (layer) {
      let existingLayer = this.getActiveLayerByGuid(layer.guid)
      if (!existingLayer) {
        this.showError('Слой не включен')
        return
      }
      await MapManager.getFeatureCountTotal(existingLayer.layer)
        .then((result) => {
          this.showSuccess('У слоя "' + layer.name + '" найдено ' + result + ' контуров')
          this.$nextTick(()=>{
            this.$set(layer, 'totalFeatures', result)
          })
        })
    },
    showHideLayersButton () {
      this.showToolsPanel = !this.showToolsPanel
      this.setComponentSize()
    },
    setComponentSize(newToolsPanelWidth) {
      if (newToolsPanelWidth) {
        this.$set(this, 'realToolsPanelWidth', newToolsPanelWidth)
      }
      this.$set(this, 'componentSize', {
        height: this.$el.clientHeight,
        width: this.$el.clientWidth,
        toolsPanelWidth: this.showToolsPanel ? this.realToolsPanelWidth : 0
      })
    },
    inputValidator (rule, value, callback) {
      let isValid = true
      let keys = Object.keys(this.fieldSourceLayers)
      for (let i = 0; i < keys.length; i++) {
        const item = this.fieldSourceLayers[keys[i]]
        if (item.validation !== 'error') {
          continue
        }
        if (this.getModel()[item.field]) {
          let fc = MapManager.createFeatureCollectionFromGeoJSON(
            this.getModel()[item.field],
            item.nativeCoordinateSystemId,
            item.declaredCoordinateSystemId
          )
          if (!MapManager.isValid(fc)) {
            isValid = false
          }
        }
      }
      if (!isValid) {
        let me = this
        window.setTimeout(() => {me.showError('Невозможно сохранить невалидную геометрию')}, 500)
      }
      return !isValid ? callback('error') : callback()
    },
    applyDynamicFilter (layer) {
      this.hideLayer(layer.guid)
      this.showLayer(layer.guid, layer.data, layer.dynamicFilters, true)
    },
    async fillMouseCoordinates (userId, additionSrsIds) {
      this.userCSs.splice(0, this.userCSs.length)
      // dashboard cs
      if (Array.isArray(additionSrsIds)) {
        for (let i = 0; i < additionSrsIds.length; i++) {
          let newSystem = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemBySrid(additionSrsIds[i]))
          // this.dashboardCSs.push(this.formatCS(newSystem[0]))
          const newSystemFormatted = this.formatCS(newSystem[0], true)
          this.userCSs.splice(this.userCSs.length, 0, newSystemFormatted)
          this.registerNewCS(newSystemFormatted)
        }
      }
      // user cs
      try {
        let userSystems = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemsByUserId(userId))
        if (Array.isArray(userSystems)) {
          userSystems.forEach((item) => {
            if (this.userCSs.findIndex((item1) => { return item1.auth_srid === item.auth_srid }) === -1) {
              this.userCSs.splice(this.userCSs.length, 0, this.formatCS(item, false))
              this.registerNewCS(item)
            }
          }, this)
        }
      } catch (e) { }
    },
    formatCS (newCS, fromDashboard) {
      if (fromDashboard !== true) {
        fromDashboard = false
      }
      const code = 'EPSG:' + newCS.auth_srid
      const proj4text = newCS.proj4text
      let label = ''
      if (newCS.description) {
        label = newCS.description
      } else if (newCS.auth_name !== 'EPSG') {
        label = newCS.auth_name
      } else if (proj4text) {
          let aa = proj4text.indexOf('+title=')
          if (aa !== -1) {
            aa += 7
            let bb = proj4text.indexOf(' +', aa)
            if (bb === -1) {
              bb = proj4text.length
            }
            label = proj4text.substring(aa, bb)
          }
      }
      return {
        label: '(' + code + ') ' + label,
        value: newCS.auth_srid,
        srid: newCS.auth_srid,
        auth_name: newCS.auth_name,
        auth_srid: newCS.auth_srid,
        proj4text: newCS.proj4text,
        fromDashboard: fromDashboard
      }
    },
    async registerNewCS (newCS) {
      const code = 'EPSG:' + newCS.auth_srid
      const proj4text = newCS.proj4text

      if (code && proj4text) {
        MapManager.addProjection(code, proj4text);
      }
    },
    async loadAndRegisterCS (newCS) {
      let system = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemBySrid(newCS))
      await this.registerNewCS(system[0])
    },
    applyShowMouseCoordinatesInteractive (enable) {
      if (!enable) {
        return
      }
      let me = this
      MapManager.setMapCoordinatesInteraction(this.map, {
        'type': EventType.PointerMove,
        'map_coordinates_callback': (coordinates, mapProjection) => {
          me.footerData.coordinates.splice(0, 1, coordinates[0])
          me.footerData.coordinates.splice(1, 1, coordinates[1])
        },
        'declared_coordinate_system_id': me.footerData.targetCS
      })
    },
    buildLayerFilters (layerFilters) {
      const filters = new FilterBuilder(
        layerFilters,
        this.getModel(),
        this.$store,
        EComponentTypes.registry
      ).buildAsApiQl()

      if (Array.isArray(filters) && filters.length === 0) {
        return undefined
      }

      return { where: { and: filters } }
    },
    actualizeInteractiveStructure (layer) {
      if (layer.properties.interactive.card) {
        if (!layer.properties.interactive.card.card) {
          layer.properties.interactive.card.card = {}
          if (Object.keys(layer.properties.interactive.card.card).length === 0) {
            let tmp = JSON.parse(JSON.stringify(layer.properties.interactive.card))
            delete tmp.card
            layer.properties.interactive.card.card = tmp
            layer.properties.interactive.card.type = 'open_card'
          }
        }
      }
      return layer
    },
    separateLayers (item) {
      if (item.type === 'group') {
        item.children = item.children.map(child => this.separateLayers(child))
      } else if (item.type === 'layer') {
        item = this.actualizeInteractiveStructure(item)
        if (
          item.properties.separation.type === 'rules'
          && item.properties.separation.rules.length > 0
          && item.properties.separation.enable !== false) {
          item = this.separateLayer(item)
        }
      }
      return item
    },
    separateLayer (layer) {
      if (layer.properties.separation.type !== 'rules' || layer.properties.separation.rules.length === 0) {
        return layer
      }
      let group = {
        guid: layer.guid,
        isVisible: true,
        leaf: layer.leaf,
        name: layer.name,
        properties: {},
        type: 'group',
        children: []
      }
      layer.properties.separation.rules.forEach((rule) => {
        let newLayer = JSON.parse(JSON.stringify(layer))
        newLayer.properties.separation.rule = rule
        newLayer.guid = this.generateGuid()
        newLayer.name = rule.name
        newLayer.properties.style = rule.style
        group.children.push(newLayer)
      })
      return group
    },
    onLayerSelect (data, value) {
      if (this.isEditor()) {
        return false
      }
      value ? this.showLayers(data) : this.hideLayers(data)
    },
    showLayers (data) {
      if (data.type === NodeLayerType.GROUP) {

      } else if (data.type === NodeLayerType.LAYER) {
        this.showLayer(data.guid, data)
        this.$set(data, 'isVisible', true)
      }
    },
    hideLayers (data) {
      if (data.type === NodeLayerType.GROUP) {

      } else if (data.type === NodeLayerType.LAYER) {
        this.hideLayer(data.guid)
        this.$set(data, 'isVisible', false)
      }
    },
    hideLayer (guid) {
      const layer = this.activeLayers.find((item) => item.guid === guid)
      if (!layer) {
        console.error(`layer not found, guid = ${guid}`)
        return false
      }
      layer.zIndexWatcher()
      MapManager.removeLayer(this.map, layer.layer)
      const indexToDel = this.activeLayers.findIndex((item) => item.guid === guid)
      this.activeLayers.splice(indexToDel, 1)
    },
    async showLayer (guid, data, dynamicFilters, isByDynamicFilters) {
      let showDynamicFilterSuccessMessage = isByDynamicFilters === true
      const layerData = data.properties
      //console.log(layerData)
      let system = await APIClient.shared.request(new MapEditorAPI.GetCoordinateSystemBySrid(layerData.source.nativeCoordinateSystemId))
      //console.log(system[0])
      this.registerNewCS(system[0])
      //return
      let layer; let filters = []
      let style = {}
      const defaultStyles = MapManager.getDefaultStyles()
      Object.keys(defaultStyles).forEach((type) => {
        if (layerData.style.hasOwnProperty(type) && (layerData.style[type] || []).length > 0) {
          style[type] = layerData.style[type].reduce((obj, item) => {
            obj[item.name] = item.value
            if (item.name === 'icon_file' && item.value.hasOwnProperty('guid')) {
              obj['image_path'] = `/files/mapeditor/images/${item.value.guid}.${item.value.extension}`
            }
            return obj
          }, {})
        } else {
          style[type] = JSON.parse(JSON.stringify(defaultStyles[type]))
        }
      })
      if (layerData.hasOwnProperty('paint')) {
        if (layerData.paint.type === 'unique_value') {
          let field = '' + layerData.paint.field
          if (layerData.source.type === 'Registry') {
            if (field.search(new RegExp('^attr_[0-9]+_', 'g')) === -1) {
              field = 'attr_' + layerData.paint.field + '_'
            }
          }
          style.unique_values = {
            field: field,
            start_color: layerData.paint.startColor
          }
          if (layerData.paint.hasOwnProperty('incrementColor') && layerData.paint.incrementColor != null) {
            style.unique_values.increment_color = layerData.paint.incrementColor
          }
        }
      }
      // loading icon
      data.isLoading = true
      let params = {
        style: style,
        // properties can contains anything
        properties: {
          name: data.name
        },
        load_callback: async (layer) => {
          console.log('loaded layer')
          if (layerData.fit) {
            await this.fitWithDefaultZoom(layer)
          }
          this.changeLayerOpacity(data, data.properties.opacity)
          // loading icon
          data.isLoading = false
        }
      }
      if (layerData.interactive.hasOwnProperty('popup') && layerData.interactive.popup.text) {
        params.feature_popup_template = layerData.interactive.popup.text
        params.feature_popup_css = layerData.interactive.popup.css
      }
      if (layerData.hasOwnProperty('viewZoomRange') && Array.isArray(layerData.viewZoomRange) && layerData.viewZoomRange.length === 2) {
        params.min_zoom = layerData.viewZoomRange[0]
        params.max_zoom = layerData.viewZoomRange[1] === 20 ? 28 : layerData.viewZoomRange[1]
      }
      const additionalFields = this.getAdditionalFields(layerData)
      switch (layerData.source.type) {
        case 'Registry':
          const url = `${this.$config.api}/mapeditor/geojson/registry/${layerData.source.entityId}`
          filters = this.buildLayerFilters(layerData.source.filters)
          // добавляю фильтры для деления слоя
          if (layerData.separation.type === 'rules' && (layerData.separation.rule.length !== 0 && layerData.separation.rule.query !== null)) {
            if (filters && filters.where && filters.where.and) {
              filters.where.and.push(layerData.separation.rule.query)
            } else {
              filters = { where: layerData.separation.rule.query }
            }
          }
          let totalDynamicFilters = 0
          //добавляю динамические фильтры
          if (dynamicFilters && Array.isArray(dynamicFilters)) {
            dynamicFilters.forEach((filter) => {
              if (filter.enable) {
                if (filter.condition === null) {
                  this.showError('Минимум у одного динамического фильтра не выбрано условие')
                  showDynamicFilterSuccessMessage = false
                  return
                }
                let field = ['xref_field', 'xref_multi_field'].includes(filter.type) ? `attr_${filter.field}_id` : `attr_${filter.field}_`
                let objectFilter = {}
                objectFilter[filter.condition] = {}

                switch (filter.condition) {
                  case 'like':
                  case 'not_like':
                    objectFilter[filter.condition][field] = '%' + filter.value + '%'
                    break;
                  case 'is_null':
                  case 'is_not_null':
                    objectFilter[filter.condition] = field
                    break;
                  default:
                    objectFilter[filter.condition][field] = '' + filter.value
                }
                if (filters && filters.where && filters.where.and) {
                  filters.where.and.push(objectFilter)
                } else {
                  filters = { where: objectFilter }
                }
                totalDynamicFilters++
              }
            })
          }
          const data1 = {
            field: `attr_${layerData.source.geometryField}_geom`,
            filter: filters,
            properties: additionalFields
          }
          if (layerData.source.marksField) {
            params.style.label.field = `attr_${layerData.source.marksField}_`
          }
          params.srs_handling = {
            native_coordinate_system_id: layerData.source.nativeCoordinateSystemId,
            declared_coordinate_system_id: this.declaredCoordinateSystemId,
            srs_handling_type: 'keep_native' // layerData.source.srsHandlingType,
          }
          params.request = {
            method: HTTPMethod.POST,
            base_url: url,
            data: data1,
            headers: { },
            axios_params: {
              hideNotification: true
            }
          }
          if (layerData.source.stateId) {
           params.request.params = {
              state_id: layerData.source.stateId
            }
           //params.state_id = layerData.source.stateId
          }
          let sourceType = SourceType.Vector
          if (params.style.point
            && params.style.point.hasOwnProperty('cluster_distance')
            && params.style.point.cluster_distance > 0) {
            sourceType = SourceType.Cluster
          }

          layer = MapManager.createLayer(sourceType, params)
          if (showDynamicFilterSuccessMessage) {
            this.showSuccess(`Применено динамических фильтров: ${totalDynamicFilters}`)
          }
          break
        case 'Field':
          let features = this.getModel()[layerData.source.geometryField]
          params.srs_handling = {
            native_coordinate_system_id: layerData.source.nativeCoordinateSystemId,
            declared_coordinate_system_id: this.declaredCoordinateSystemId,
            srs_handling_type: layerData.source.srsHandlingType
          }
          if (features === undefined || features === null) {
            layer = MapManager.createLayer(SourceType.Vector, params)
          } else {
            layer = MapManager.createLayerFromGeoJSON(features, params)
          }
          break
        case 'XYZ':
          layer = MapManager.createLayer(SourceType.XYZ, {
            url: layerData.source.externalURL,
            load_callback:  async (layer) => {
              this.changeLayerOpacity(data, data.properties.opacity)
              // loading icon
              data.isLoading = false
            }
          })
          break
        case 'TileArcGISRest':
          layer = MapManager.createLayer(SourceType.TileArcGISRest, {
            url: layerData.source.externalURL,
            load_callback: async () => {
              if (layerData.fit) {
                await this.fitWithDefaultZoom(layer)
              }
            }
          })
          break
        case 'TileWMS':
        case 'WMS':
          layer = MapManager.createLayer(SourceType.TileWMS, {
            url: layerData.source.externalURL,
            load_callback: async () => {
              if (layerData.fit) {
                await this.fitWithDefaultZoom(layer)
              }
            }
          })
          break
        case 'WFS':
          params.srs_handling = {
            'native_coordinate_system_id': layerData.source.nativeCoordinateSystemId,
            'declared_coordinate_system_id': this.declaredCoordinateSystemId,
            'srs_handling_type': 'keep_native'
          }
          params.request = {
            method: HTTPMethod.GET,
            base_url: layerData.source.externalURL,
            headers: null,
            axios_params: {
              hideNotification: true
            }
          }
          if (layerData.source.geometryField !== null && layerData.source.geometryField !== '') {
            params.request.geometry_name = layerData.source.geometryField
          }
          // добавляю cql фильтры для деления слоя
          if (layerData.separation.type === 'rules' && (layerData.separation.rule.length !== 0 && layerData.separation.rule.query !== null)) {
            params.request.cql_filter = layerData.separation.rule.script
          }
          if (dynamicFilters && Array.isArray(dynamicFilters)) {
            dynamicFilters.forEach((filter) => {
              if (filter.enable) {
                const filterString = filter.field + ' LIKE %' + filter.value + '%'
                if (params.request.cql_filter) {
                  params.request.cql_filter += ' AND ' + filterString
                } else {
                  params.request.cql_filter = filterString
                }
              }
            })
          }
          layer = MapManager.createLayer(SourceType.Vector, params)
          break
        default:
          console.error(`unknown source type: ${layerData.source}`)
          return
      }
      MapManager.addLayer(this.map, layer)
      MapManager.setZIndex(layer, layerData.zIndex)
      this.activeLayers.push({
        guid: guid,
        name: data.name,
        layer: layer,
        layerData: layerData,
        filters: layerData.source.filters,
        dynamicFilters: layerData.source.dynamicFilters,
        lastFilters: filters,
        data: data,
        zIndexWatcher: this.$watch(() => layerData.zIndex, (zIndex) => {
          MapManager.setZIndex(layer, zIndex)
        })
      })
      if (layerData.source.type === 'Field' && layerData.isEditable) {
        this.fieldSourceLayers[guid] = {
          validation: layerData.validation,
          field: layerData.source.geometryField,
          nativeCoordinateSystemId: layerData.source.nativeCoordinateSystemId,
          declaredCoordinateSystemId: this.declaredCoordinateSystemId
        }
      }
    },
    getAdditionalFields (layerData) {
      let result = []
      if (layerData.source.marksField) {
        result.push(layerData.source.marksField)
      }
      if (layerData.interactive) {
        if (layerData.interactive.card && layerData.interactive.card.card && layerData.interactive.card.card.fieldId) {
          result.push(layerData.interactive.card.fieldId)
        }
      }
      if (
        layerData.interactive &&
        layerData.source.type === 'Registry' &&
        layerData.interactive.standardCard
      ) {
        layerData.interactive.standardCard.fields.forEach((item) => {
          result.push(item.id)
        })
      }
      if (layerData.source.type === 'Registry') {
        result = result.map((item) => `attr_${item}_`)
        result.push('guid')
      }
      return result
    },
    changeLayerOpacity (layer, opacity) {
      let mapLayer = this.activeLayers.find((item) => { return item.guid === layer.guid })
      if (mapLayer) {
        MapManager.setOpacity(mapLayer.layer, opacity)
      }
    },
    changeSelectInteractionType (type) {
      if (type === this.currentInteraction) {
        type = null
      }
      this.currentInteraction = type
    },
    removeInteraction (oldValue) {
      switch (oldValue) {
        // drawing
        case INTERACTION_MODES.DRAWING.LINESTRING:
        case INTERACTION_MODES.DRAWING.POINT:
        case INTERACTION_MODES.DRAWING.POLYGON:
          this.setToolsCheckMarks('drawing-geometry', null)
          MapManager.clearInteractions(this.map, [InteractionType.Draw])
          break;
        // edit
        case INTERACTION_MODES.EDIT:
          MapManager.clearInteractions(this.map, [InteractionType.Modify])
          break;
        // measure
        case INTERACTION_MODES.MEASURE.LINE:
        case INTERACTION_MODES.MEASURE.SQUARE:
          this.setToolsCheckMarks('measurement', null)
          MapManager.clearInteractions(this.map, [InteractionType.Measure])
          break;
        case INTERACTION_MODES.SELECT.POINT:
        case INTERACTION_MODES.SELECT.RECTANGLE:
        case INTERACTION_MODES.SELECT.POLYGON:
        case INTERACTION_MODES.SELECT.INTERSECTION:
          this.setToolsCheckMarks('change-selection-type', null)
          MapManager.clearInteractions(this.map, [InteractionType.Select])
          break;
        case INTERACTION_MODES.SELECT.ROSREESTR:
          this.$set(this, 'showCadastrPanel', false)
          this.setToolsCheckMarks('change-selection-type', null)
          MapManager.clearCenterMarkers(this.map)
          MapManager.clearInteractions(this.map, [InteractionType.MapCoordinatesClick])
          break;
        case INTERACTION_MODES.DEFINE_ADDRESS:
          this.setToolsCheckMarks('position-on', null)
          MapManager.clearInteractions(this.map, [InteractionType.MapCoordinatesClick])
          MapManager.setCursor(this.map, CursorType.Default)
          MapManager.clearCenterMarkers(this.map)
          break;
        case null:
          break;
        default:
          console.log('unknown interaction', oldValue)
      }
    },
    applyInteraction (newValue) {
      let me = this
      switch (newValue) {
        case INTERACTION_MODES.DRAWING.LINESTRING:
        case INTERACTION_MODES.DRAWING.POINT:
        case INTERACTION_MODES.DRAWING.POLYGON:
          this.activeInteractiveType.splice(0, 1, 'drawing-geometry')
          this.setToolsCheckMarks('drawing-geometry', newValue)
          const activeLayer = this.getActiveLayer(true)
          if (activeLayer === false) {
            return
          }
          MapManager.setDrawInteraction(
            this.map, // map to draw on
            activeLayer.layer, // layer to draw on
            {
              geometry_type: newValue, // feature type to be drawn
              draw_callback: function (geoJSON) {
                if (activeLayer.layerData.source.type === 'Field') {
                  // console.log(me.getActiveLayerFeatures())
                  me.getModel()[activeLayer.layerData.source.geometryField] = me.getActiveLayerFeatures()
                }
                me.currentInteraction = null
              }
            }
          )
          break;
        // edit
        case INTERACTION_MODES.EDIT:
          this.activeInteractiveType.splice(0, 1, 'drawing-geometry')
          const activeLayer2 = this.getActiveLayer(true)
          if (activeLayer2 === false) {
            return
          }
          MapManager.setModifyInteraction(this.map,
            {
              'source': activeLayer2.layer,
              'modify_callback': function (features) {
                me.getModel()[activeLayer2.layerData.source.geometryField] = me.getActiveLayerFeatures()
              }
            })
          break;
        // measure
        case INTERACTION_MODES.MEASURE.LINE:
        case INTERACTION_MODES.MEASURE.SQUARE:
          this.activeInteractiveType.splice(0, 1, 'measurement')
          this.setToolsCheckMarks('measurement', newValue)
          MapManager.setMeasureInteraction(
            this.map,
            {
              measure_type: newValue,
              measure_popup_settings: {
                'distance_units': this.$locale.map_editor.units.m,
                'area_units': this.$locale.map_editor.units.sqm,
                'rotation_caption': this.$locale.map_editor.units.rotation,
                'angle_caption': this.$locale.map_editor.units.angle
              },
              measure_callback: () => {
                this.currentInteraction = null
              }
            }
          )
          break;
        // select
        case INTERACTION_MODES.SELECT.POINT:
        case INTERACTION_MODES.SELECT.RECTANGLE:
        case INTERACTION_MODES.SELECT.POLYGON:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          MapManager.setSelectInteraction(this.map, {
            'selection_type': this.currentInteraction,
            'multiple': false,
            'pin': this.isSelectInteractionPin,
            'select_callback': this.onFeaturesClick
          })
          break;
        case INTERACTION_MODES.SELECT.INTERSECTION:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          MapManager.setSelectInteraction(this.map, {
            'selection_type': 'polygon'/*this.currentInteraction*/,
            'select_callback': this.intersectionFeatureCollection
          })
          break;
        case INTERACTION_MODES.SELECT.ROSREESTR:
          this.activeInteractiveType.splice(0, 1, 'change-selection-type')
          this.setToolsCheckMarks('change-selection-type', newValue)
          this.$set(this, 'showToolsPanel', false)
          MapManager.setMapCoordinatesInteraction(this.map, {
            'type': EventType.Click,
            'declared_coordinate_system_id': 4326,
            'map_coordinates_callback': this.onMapClick
          })
          break;
        case INTERACTION_MODES.DEFINE_ADDRESS:
          this.activeInteractiveType.splice(0, 1, 'position-on')
          this.setToolsCheckMarks('position-on', newValue)
          MapManager.setMapCoordinatesInteraction(this.map, {
            'type': EventType.Click,
            'declared_coordinate_system_id': 4326,
            'map_coordinates_callback': this.onDefineAddressClick
          })
          MapManager.setCursor(this.map, CursorType.Pointer)
          break;
        case null:
          this.activeInteractiveType.splice(0, this.activeInteractiveType.length)
          break;
        default:
          console.log('unknown interaction', newValue)
      }
    },
    setToolsCheckMarks(key, value) {
      if (this.toolsCheckMarks[key] === undefined) {
        this.$set(this.toolsCheckMarks, key, [])
      }
      if (value === null) {
        this.toolsCheckMarks[key].splice(0, this.toolsCheckMarks[key].length)
      } else {
        if (this.toolsCheckMarks[key].length > 0) {
          //если там уже что-то есть
          let index = this.toolsCheckMarks[key].indexOf(value)
          if (index !== -1) {
            //добавляемое значение уже есть - удаляем
            this.toolsCheckMarks[key].splice(index, 1)
            return
          } else {
            //иначе добавляем
            this.toolsCheckMarks[key].splice(this.toolsCheckMarks[key].length, 0, value)
          }
        } else {
          //если пустой массив
          this.toolsCheckMarks[key].splice(0, this.toolsCheckMarks[key].length, value)
        }
      }
    },
    measurement (type) {
      switch (type) {
        case 'distance':
        case 'area':
          if (this.currentInteraction === type) {
            this.currentInteraction = null
          } else {
            this.currentInteraction = type
          }
          break
        case 'reset':
          MapManager.clearMeasureResult(this.map)
          this.currentInteraction = null
          break
        default:
          console.log('unknown measurement type')
      }
    },
    closeCadastrPanel () {
      this.showCadastrPanel = false
      MapManager.clearCenterMarkers(this.map)
    },
    showPositionOnWindow (type) {
      let toolProperties = this.tools['position-on'].properties
      switch (type) {
        case 'defineAddress':
          if (type === this.currentInteraction) {
            type = null
          }
          this.currentInteraction = type
          break;
        case 'address':
          let inputValue = ''
          if (toolProperties !== undefined && toolProperties.field !== undefined) {
            if (toolProperties.field) {
              let fieldValue = this.getModel()[toolProperties.field]
              if (fieldValue) {
                switch (typeof fieldValue) {
                  case 'string':
                    inputValue = fieldValue
                    break
                  case 'object':
                    if (Array.isArray(fieldValue)) {
                      if (fieldValue.length > 0) {
                        fieldValue = fieldValue[0]
                      }
                    }
                    inputValue = fieldValue.name || ''
                    break
                  default:
                    console.log('Unknown associated field!')
                }
              }
            }
          }
          this.positionOnAddressSettings = {
            addressPrefix: toolProperties.defaultAddress,
            valueByDefault: inputValue
          }
        case 'coordinates':
          this.$refs['position-on-window'].show(type)
          break;
        default:
          console.log('position-on tool error')
          break;
      }
    },
    async positionOn (coordinates, cs) {
      await this.loadAndRegisterCS(cs)
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: coordinates.x,
          y: coordinates.y,
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: cs
        }
      )
    },
    getActiveLayer (isEditable) {
      const activeLayerGuid = this.$refs.tools_panel.getActiveLayerGuid()
      // const activeLayerGuid = this.$refs.layers_tree.getActiveLayerGuid()
      if (!activeLayerGuid) {
        this.showError('Не выбран активный слой')
        return false
      }
      const activeLayerData = this.activeLayers.find((item) => item.guid === activeLayerGuid)
      if (!activeLayerData) {
        this.showError('Активный слой не включен')
        return false
      }
      if (isEditable && !activeLayerData.layerData.isEditable) {
        this.showError('Активный слой является нередактируемым')
        return false
      }
      return activeLayerData
    },
    getActiveLayerFeatures () {
      const activeLayerData = this.getActiveLayer(true)
      if (activeLayerData === false) {
        return false
      }
      // console.log(activeLayerData)
      const fc = MapManager.getFeatures(activeLayerData.layer)

      if (activeLayerData.layerData.validation === 'warning') {
        console.log(MapManager.isValid(fc))
        if (!MapManager.isValid(fc)) {
          this.showError('Один из контуров не является валидным')
        }
      }

      let geometry = ''
      if (fc.isSingle()) {
        geometry = MapManager.getFeaturesAsSingleGeometry(fc, activeLayerData.layer.srsId)
      }
      if (fc.isMixed()) {
        geometry = MapManager.getFeaturesAsGeometryCollection(fc, activeLayerData.layer.srsId)
      }
      if (!fc.isSingle() && !fc.isMixed()) {
        geometry = MapManager.getFeaturesAsMultiGeometry(fc, activeLayerData.layer.srsId)
      }
      return geometry
    },
    search () {
      this.$set(this, 'isLoading', !this.isLoading)
    },
    refreshGeometryField (showMessage, newFeature) {
      const activeLayer = this.getActiveLayer(true)
      if (activeLayer === false) {
        return
      }
      this.getModel()[activeLayer.layerData.source.geometryField] = this.getActiveLayerFeatures()
      if (newFeature) {
        this.zoomOnFeature(newFeature, false)
      } else {
        MapManager.fitLayer(this.map, activeLayer.layer)
      }
      if (showMessage) {
        if (showMessage === true) {
          this.showSuccess('Успешно добавлено')
        } else {
          this.showSuccess(showMessage)
        }
      }
    },
    drawingGeometry (type) {
      const activeLayer = this.getActiveLayer(true)
      if (activeLayer === false) {
        return
      }
      let toolProperties = this.tools['drawing-geometry'].properties
      const me = this
      switch (type) {
        case 'Point':
        case 'LineString':
        case 'Polygon':
          if (Object.values(INTERACTION_MODES.DRAWING).includes(this.currentInteraction)) {
            me.currentInteraction = null
            this.snapMode = false
          } else {
            me.currentInteraction = type
          }
        break;
        case 'Snap':
          if (this.snapMode) {
            MapManager.clearInteractions(this.map, [InteractionType.Snap])
            this.setToolsCheckMarks('drawing-geometry', 'Snap')
            this.snapMode = false
          } else {
            if (Object.values(INTERACTION_MODES.DRAWING).includes(this.currentInteraction)) {
              if (toolProperties && toolProperties.snap && toolProperties.snap.enable) {
                MapManager.setSnapInteraction(this.map, {
                  layers: [activeLayer.layer],
                  pixelTolerance: toolProperties.radius
                })
              }
              this.setToolsCheckMarks('drawing-geometry', 'Snap')
              this.snapMode = true
            }
          }
          break;
        case 'Vertices':
          this.createGeometryByVertices.layer = activeLayer.layer
          this.$refs['create-geometry-by-vertices'].show()
          break;
        case 'EditByVertices':
          // пока так, как появится в отдельной кнопке - перенесем
          me.isEditVerticesActive = !me.isEditVerticesActive
          if (me.isEditVerticesActive) {
            me.currentInteraction = type
          } else {
            me.currentInteraction = null
          }
          break;
        default:
          console.log('Неизвестный тип создания геометрии')
      }

    },
    deleteFeatures () {
      if (this.activeFeatures.length === 0) {
        this.$message.error('Не выбрана геометрия для удаления')
        return false
      }
      this.$confirm(`Вы уверены что хотите удалить выбранные геометрии (${this.activeFeatures.length} шт.)`, 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет'
      }).then(() => {
        let count = 0
        for (let i = 0; i < this.activeFeatures.length; i++) {
          const a = this.deleteGeometry(this.activeFeatures[i], true)
          if (a) {
            this.activeFeatures.splice(i, 1)
            count++
          }
        }
        if (count === 0) {
          this.$message.error('Выбранные геометрии находятся на нередактируемом слое')
        } else {
          if (count < this.activeFeatures.length) {
            this.$message.success(`Удалено ${count} геометрий, ${1 + this.activeFeatures.length - count} находятся на нередактируемых слоях`)
          } else {
            this.$message.success(`Удалено ${count} геометрий`)
          }
          this.activeFeatures.splice(0, this.activeFeatures.length)
        }
      })
    },
    deleteGeometry (item, hideMessage) {
      //item = (element|element guid) of this.activeFeatures
      if (typeof item === 'string') {
        item = this.activeFeatures.find(x => x.properties.guid === item)
      }
      if (!item.layerProperties.isEditable) {
        if (!hideMessage) {
          this.$message.error('Не редактируемый слой')
        }
        return false
      }
      if (item.layerProperties.source.type === 'Field') {
        // очистить поле с геометрией
        MapManager.removeFeatures(this.map, new FeatureCollection([item.item]))
        this.getModel()[item.layerProperties.source.geometryField] = this.getActiveLayerFeatures()
        return true
      }
      if (item.layerProperties.source.type !== 'Registry') {
        return false
      }

      let formData = new FormData()
      let id = item.properties['id']
      formData.append('id', id)
      formData.append('guid', '"' + item.properties.guid + '"')
      formData.append(`attr_${item.layerProperties.source.geometryField}_`, null)
      this.isLoading = true
      this.$http.put(
        `${this.$config.api}/registryservice/registry/${item.layerProperties.source.entityId}/records/${id}`,
        formData,
        {
          hideNotification: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(() => {
          this.removeFeature(item.item)
          this.isLoading = false
          this.activeFeatures.splice(this.activeFeatures.findIndex(x => x.properties.guid === item.properties.guid), 1)
        })
        .catch((e) => {
          console.log(e)
          console.log('удалить из базы не получилось')
          this.isLoading = false
        })
      return true
    },
    removeFeature (feature) {
      MapManager.removeFeatures(this.map, new FeatureCollection([feature]))
    },
    showSuccess (text) {
      this.$message({
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: text
      })
    },
    showError (text) {
      this.$message({
        type: 'error',
        dangerouslyUseHTMLString: true,
        message: text
      })
    },
    exportFromToolsPanel(action) {
      if (action === 'excel') {
        this.exportFeatures('features-excel')
      }
    },
    async onDefineAddressClick (coordinates) {
      const toolProperties = this.tools['position-on'].properties
      if (!toolProperties.isDefineAddressEnable || toolProperties.defineAddressMapping.length === 0) {
        this.showError('Настройки "Определить адрес" не найдены')
        return
      }
      //MapManager.
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: coordinates[0],
          y: coordinates[1],
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: 4326
        }
      )
      let daData = axios.create()
      daData.interceptors.request.use(function (config) {
        config.headers = {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': 'Token 03fab14d0555d6f7a9ea39754dbdc4771b4c3a8a'
        }
        return config
      }, function (error) {
        return Promise.reject(error)
      })
      daData
        .post(
          `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`,
          { lat: coordinates[1], lon: coordinates[0] },
          { hideNotification: true }
        )
        .then((response) => {
          if (!response.data.suggestions || response.data.suggestions.length < 1) {
            this.showError('В указанной точке ничего не найдено')
            return
          }
          toolProperties.defineAddressMapping.forEach((mappingItem) => {
            let valueName = mappingItem.value_id.split('.')
            let value = response.data.suggestions[0]
            valueName.forEach((vn) => {
              value = value[vn]
            })
            this.getModel()[mappingItem.field_id] = value
          })
        })
    },
    async onMapClick (coordinates) {
      //this.onRosreestrClick([43.581577, 58.154024])
      let centerCoordinates = coordinates
      let isTargetNotEmpty = false
      // участок
      this.$set(this, 'showCadastrPanel', false)
      let areaTarget = await APIClient.shared.request(new RosreestrAPI.GetAreaByCoordinates(coordinates))
      if (areaTarget.total > 0) {
        centerCoordinates = [areaTarget.features[0].center.x, areaTarget.features[0].center.y]
        isTargetNotEmpty = true
        let areaInfo = await APIClient.shared.request(new RosreestrAPI.GetAreaInfoByCadastrNumber(areaTarget.features[0].attrs.id))
        // console.log(areaInfo)
        const area = areaInfo.feature.attrs
        this.$set(this.cadastrInfo, 'area', {
          cn: area.cn,
          category_type: area.category_type,
          address: area.address,
          area_value: area.area_value,
          cad_cost: area.cad_cost
        })
        this.$set(this, 'showCadastrPanel', true)
      } else {
        this.$set(this.cadastrInfo, 'area', {})
      }
      let buildingTarget = await APIClient.shared.request(new RosreestrAPI.GetBuildingByCoordinates(coordinates))
      if (buildingTarget.total > 0) {
        centerCoordinates = [buildingTarget.features[0].center.x, buildingTarget.features[0].center.y]
        isTargetNotEmpty = true
        let buildingInfo = await APIClient.shared.request(new RosreestrAPI.GetBuildingInfoByCadastrNumber(buildingTarget.features[0].attrs.id))
        const building = buildingInfo.feature.attrs
        this.$set(this.cadastrInfo, 'building', {
          cn: building.cn,
          name: building.name,
          purpose: building.purpose,
          address: building.address,
          year_built: building.year_built,
          area_value: building.area_value,
          cad_cost: building.cad_cost,
          floors: building.floors
        })
        this.$set(this, 'showCadastrPanel', true)
      } else {
        this.$set(this.cadastrInfo, 'building', {})
      }
      if (!isTargetNotEmpty) {
        this.showError('Публичная кадастровая карта не содержит сведений по выбранным координатам')
      }
      MapManager.clearCenterMarkers(this.map)
      MapManager.setCenter(
        this.map,
        {
          x: centerCoordinates[0],
          y: centerCoordinates[1],
          show_marker: this.position.show_marker,
          declared_coordinate_system_id: isTargetNotEmpty ? 3857 : 4326
        }
      )
    },
    async onFeaturesClick (data) {
      let interactive = []
      this.showCardsPanel = false
      for (const item of data.features) {
        const layer = this.activeLayers.find((layer) => item.layer.layer === layer.layer.layer)
        if (layer) {
          let style = item.layer.layer.getStyle()(item.getFeature())
          let label = ''
          // костыль похоже
          if (Array.isArray(style)) {
            style = style.pop()
          }
          if (style) {
            if (style.getText() !== null) {
              label = style.getText().getText()
            }
          }
          let properties = item.getFeature().getProperties()
          const measureUnits = this.featureMetrics.units || 'kilometers'
          let numbersAfterDot = 1
          for (let i = 0; i < (this.featureMetrics.numbersAfterDot || 2); i++) {
            numbersAfterDot *= 10
          }
          interactive.push({
            settings: layer.layerData.interactive,
            properties: properties,
            layerName: layer.name,
            layerGuid: layer.guid,
            layerProperties: layer.layerData,
            measures: {
              length_m: Math.round(MapManager.getLength(item, 'meters', 3857) * numbersAfterDot) / numbersAfterDot,
              length_km: Math.round(MapManager.getLength(item, 'kilometers', 3857) * numbersAfterDot) / numbersAfterDot,
              area_m: Math.round(MapManager.getArea(item, 'meters', 3857) * numbersAfterDot) / numbersAfterDot,
              area_km: Math.round(MapManager.getArea(item, 'kilometers', 3857) * numbersAfterDot) / numbersAfterDot,
              units: measureUnits,
            },
            label: label,
            featureType: item.getType(),
            nativeCoordinateSystemId: layer.layerData.source.nativeCoordinateSystemId,
            coordinates: [],
            item: item,
            feature: item.getFeature()
          })
        }
      }
      // если клик по одному объекту и по умолчанию открытие карточки реестра, то открываем
      if (interactive.length === 1) {
        let object = interactive[0]
        if (object.layerProperties.interactive.type === 'open_registry_card') {
          this.openCard(object.settings.card.card, object.properties)
          return false
        }
      }

      this.activeFeatures = interactive
      this.selectedObjects = interactive
      if (interactive.length !== 0) {
        if (!this.showToolsPanel) {
          this.showToolsPanel = true
        }
        this.$refs.tools_panel.openTabByName('objects_settings')
      }
    },
    openCardFromPanel (object) {
      this.openCard(object.settings, object.properties)
    },
    setZoom (newZoomValue) {
      const view = this.map.map.getView()
      const newZoom = view.getConstrainedZoom(newZoomValue)
      if (view.getAnimating()) {
        view.cancelAnimations()
      }
      view.animate({
        zoom: newZoom,
        duration: 250,
        easing: easeOut
      })
    },
    async fitWithDefaultZoom (layer) {
      await MapManager.fitLayer(this.map, layer)
      const view = this.map.map.getView()
      const currentZoom = view.getZoom()
      if (currentZoom > this.defaultZoom) {
        this.setZoom(this.defaultZoom)
      }
    },
    changeZoom (type) {
      let delta = 0
      if (type === 'plus') {
        delta += 1
      } else {
        delta -= 1
      }
      const view = this.map.map.getView()
      const currentZoom = view.getZoom()
      const newZoom = view.getConstrainedZoom(currentZoom + delta)
      if (view.getAnimating()) {
        view.cancelAnimations()
      }
      view.animate({
        zoom: newZoom,
        duration: 250,
        easing: easeOut
      })
    },
    zoomOnFeature (item, showCenterMarker) {
      MapManager.fitFeatures(this.map, new FeatureCollection([item.feature]), this.defaultZoom, showCenterMarker ?? false)
    },
    getDefaultCenter () {
      let object = {
        x: null,
        y: null
      }
      if (this.defaultCenter) {
        const x = this.defaultCenter.split(',')[0].trim()
        const y = this.defaultCenter.split(',')[1].trim()
        if (typeof x !== 'undefined' && typeof y !== 'undefined') {
          object.x = x
          object.y = y
        }
      }

      return object
    },
    async googleStreetView () {
      this.isGoogleStreetViewActive = !this.isGoogleStreetViewActive
      let me = this
      if (this.isGoogleStreetViewActive) {
        if (this.google === null) {
          let loader = new google.Loader('AIzaSyCLPWXu_Cc8sEN1g-QsmHTPd5a2kvXlpMQ')
          await loader.load()
            .then(function (google) { me.google = google })
            .catch((answer) => { me.showError('Ошибка загрузки') })
        }
        let svContainer = this.$refs['street-view']
        const view = this.map.map.getView()
        const coord = this.transform(
          view.getCenter(),
          this.declaredCoordinateSystemId,
          this.googleMapsCoordinateSystemId
        )
        if (!this.googlePanorama) {
          this.googlePanorama = new this.google.maps.StreetViewPanorama(svContainer, {
            position: { lat: coord[1], lng: coord[0] },
            pov: { heading: 165, pitch: 0 },
            zoom: 0,
            visible: true
          })
          this.googlePanorama.addListener('position_changed', () => {
            const coord = this.transform(
              [
                this.googlePanorama.getPosition().lng(),
                this.googlePanorama.getPosition().lat()
              ],
              this.googleMapsCoordinateSystemId,
              this.declaredCoordinateSystemId
            )
            view.setCenter(coord)
          })
        } else {
          this.googlePanorama.setPosition(
            new this.google.maps.LatLng(coord[1], coord[0])
          )
        }
        this.map.map.on('click', e => {
          if (this.isGoogleStreetViewActive) {
            const coord = this.transform(
              e.coordinate,
              this.declaredCoordinateSystemId,
              this.googleMapsCoordinateSystemId
            )
            this.googlePanorama.setPosition(
              new this.google.maps.LatLng(coord[1], coord[0])
            )
          }
        })
      }
      me.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(me.map), 0)
      })
    },
    async importGeoJSONFeatures (featureCollection, srsId) {
      const activeLayerData = this.getActiveLayer(true)
      await this.loadAndRegisterCS(srsId)
      const fc = MapManager.createFeatureCollectionFromGeoJSON(
        JSON.stringify(featureCollection),
        activeLayerData.layerData.source.nativeCoordinateSystemId,
        this.declaredCoordinateSystemId
      )
      MapManager.addFeatures(this.map, activeLayerData.layer, fc)
      MapManager.fitFeatures(this.map, fc)
      this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()
      this.$message({
        message: `Было добавлено ${featureCollection.features.length} геометрий`,
        type: 'success'
      })
    },
    async importWKTString (stringWKT, srsId) {
      const activeLayerData = this.getActiveLayer(true)
      await this.loadAndRegisterCS(srsId)
      const feature = MapManager.createFeatureFromText(
        stringWKT,
        GeometryFormat.WKT,
        srsId,
        this.declaredCoordinateSystemId
      )
      MapManager.addFeatures(
        this.map,
        activeLayerData.layer,
        new FeatureCollection([feature])
      )

      this.getModel()[activeLayerData.layerData.source.geometryField] = this.getActiveLayerFeatures()

      this.$message({
        message: `Геометрия успешно импортирована`,
        type: 'success'
      })
    },
    async exportFeatures (type) {
      const cellStyle = { bold: true }
      switch (type) {
        case 'features-excel':
          const ExcelJS = require('exceljs')
          const workbook = new ExcelJS.Workbook()
          workbook.addWorksheet('Sheet1')
          const worksheet = workbook.getWorksheet('Sheet1')
          const column = worksheet.getColumn(1)

          let i = 1
          Object.values(this.selectedObjects).forEach((layer) => {
            // set header
            const row = worksheet.getRow(i++)
            row.getCell(1).value = `Слой "${layer.name}"`
            row.getCell(1).font = cellStyle
            let j = 2
            //TODO переделать layersInteractiveSettings больше нет!
            /*this.layersInteractiveSettings[layer.guid].interactive.standardCard.fields.forEach((field) => {
              row.getCell(j).value = field.label
              row.getCell(j).font = cellStyle
              j++
            })
            */
            //set values
            let count = 1
            Object.values(layer.items).forEach((item) => {
              const row = worksheet.getRow(i++)
              row.getCell(1).value = count++
              j = 2
              item.standardCardProperties.forEach((property) => {
                row.getCell(j++).value = property.value
              })
            })
            i++
          })
          const buffer = await workbook.xlsx.writeBuffer()
          let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let url = window.URL.createObjectURL(blob)
          window.open(url)
          break;
        case 'png':
          await MapManager.export(this.map, ExportType.PNG)
          break;
        case 'geoTiff':
          const exportInfo = await MapManager.export(this.map, ExportType.GeoTIFF)
          await APIClient.shared.request(new DotNetSpatialServiceAPI.ConvertToGeoTiff(exportInfo))
          break;
        default:
          console.log('Неизвестный тип экспорта')
          return
      }
    },
    async importFeatures (type) {
      const activeLayerData = this.getActiveLayer(true)
      let me = this
      const h = this.$createElement
      const countFeaturesBefore = me.getActiveLayerFeatures()
      switch (type) {
        case 'file-dxf':
          if (activeLayerData === false) {
            return
          }
          let str = activeLayerData.layerData.source.geometryField
          this.importLayerGeometryField = str.substr(str.indexOf('_') + 1, str.length - str.indexOf('_') - 2)
          this.$refs['import_features'].showImportFileWindow()
          break
        case 'string-wkt':
          if (activeLayerData === false) {
            return
          }
          this.$refs['import_features'].showImportWKTWindow()
          break
        default:
          console.log('Неизвестный тип импорта')
          return
      }

    },
    intersectionFeatureCollection (data) {
      let layers = []
      data.features.forEach((feature) => {
        if (!layers.includes(feature.layer.properties.name)) {
          layers.push(feature.layer.properties.name)
        }
      })
      this.showIntersectionMessage(layers)
    },
    intersectionFeature (feature) {
      let layers = []
      MapManager.getFeatureIntersectedLayers(feature.feature).forEach((layer) => {
        if (!layers.includes(layer.properties.name)) {
          layers.push(layer.properties.name)
        }
      })
      this.showIntersectionMessage(layers)
    },
    showIntersectionMessage (layers) {
      this.intersection.layers.splice(0, this.intersection.layers.length)
      let message = ''
      if (layers.length === 0) {
        message = 'Пересечение с выбранными слоями не найдено'
      } else {
        message = 'Обнаружено пересечение со слоями:'
      }
      layers.forEach((layer) => {
        this.intersection.layers.splice(this.intersection.layers.length, 0, layer)
      })
      this.intersection.message = message
      this.intersection.showWindow = true
    },
    transform (coordinate, systemFrom, systemTo) {
      let systems = [
        3857,
        4326
      ]
      if (systemFrom === systemTo) {
        return coordinate
      }
      if (systems.indexOf(systemFrom) !== false && systems.indexOf(systemTo) !== false) {
        return OlProj.transform(
          coordinate,
          'EPSG:' + systemFrom,
          'EPSG:' + systemTo
        )
      }
      // error
      return coordinate
    },
    expand () {
      if (this.isEditor()) {
        return
      }
      this.isExpanded = !this.isExpanded
      if (this.expandedSettings.notExpandedStyle === null) {
        let tab = false
        let parent = this
        let tabSelectionHeight = 0
        let prev = null
        while (parent !== undefined) {
          if (this.getInterfaceEditorVersion() === 2) {
            if (parent.$options['_componentTag'] === 'InterfaceViewerV2') {
              this.expandedSettings.v2.interfaceViewer = parent
              this.expandedSettings.v2.container = parent.$el.querySelector('.container')
              this.expandedSettings.v2.defaultContainerCssText = this.expandedSettings.v2.container.style.cssText
            }
          }
          if (parent.$options['_componentTag'] === 'grid-item') {
            this.expandedSettings.containers.push({
              container: parent,
              defaultStyleZIndex: parent.style.zIndex,
              defaultStyleTransform: parent.style.transform,
              defaultStyleTop: parent.style.top,
              defaultStyleLeft: parent.style.left
            })
          }
          if (parent.$options['_componentTag'] === 'Dashboard') {
            this.expandedSettings.dashboard.dom = parent.$el.querySelector('.dashboard_form')
            if (this.expandedSettings.dashboard.dom !== null) {
              this.expandedSettings.dashboard.defaultWidth = this.expandedSettings.dashboard.dom.style.width
            }
          }
          // панель вкладки
          if (parent.$options['_componentTag'] === 'el-tab-pane') {
            if (tab) {
              tabSelectionHeight += tab.$el.offsetParent.offsetTop
            }
            tab = parent
          }
          // фрейм
          if (parent.$options['_componentTag'] === 'dashboard') {
            tabSelectionHeight = 0
            tab = false
          }
          prev = parent
          parent = parent.$parent
        }
        this.expandedSettings.tab = tab
        this.expandedSettings.tabSelectionHeight = tabSelectionHeight
        this.expandedSettings.notExpandedStyle = this.$refs['map-container']
      }
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(entities => {
          this.resizeMap()
        })
      }
      if (this.isExpanded) {
        this.resizeObserver.observe(this.expandedSettings.tab.$el)
      } else {
        this.resizeObserver.unobserve(this.expandedSettings.tab.$el)
        if (this.hideToolsPanelAfterExpand) {
          this.$set(this, 'showToolsPanel', false)
        }
      }
      this.resizeMap()
      this.$refs['tools'].changeActive('expand')
    },
    resizeMap () {
      let mapContainer = this.$refs['map-container']
      // get tab and tab containers
      let top = this.expandedSettings.tab.$el.offsetParent.offsetTop + this.expandedSettings.tabSelectionHeight
      let left = this.expandedSettings.tab.$el.offsetParent.offsetLeft
      let height = this.expandedSettings.tab.$el.clientHeight - this.expandedSettings.tabSelectionHeight
      let width = this.expandedSettings.tab.$el.clientWidth
      // console.log(this.tabSettings)
      if (this.isExpanded) {
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.zIndex = '1'
          item.container.style.transform = ''
          item.container.useCssTransforms = false
        })
        // map container changes
        mapContainer.style.position = 'fixed'
        mapContainer.style.width = (width - 20) + 'px'
        mapContainer.style.height = (height - 20) + 'px'
        mapContainer.style.zIndex = '999'
        mapContainer.style.top = (top + 10) + 'px'
        mapContainer.style.left = (left + 10) + 'px'
        mapContainer.style.transform = ''
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = '0'
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().hideButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText = 'width: 1px;'
          }
        }
      } else {
        mapContainer.style = this.expandedSettings.notExpandedStyle
        this.expandedSettings.containers.forEach((item) => {
          item.container.style.left = item.defaultStyleLeft
          item.container.style.top = item.defaultStyleTop
          item.container.style.zIndex = item.defaultStyleZIndex
          item.container.style.transform = item.defaultStyleTransform
          item.container.useCssTransforms = true
        })
        if (this.expandedSettings.dashboard.dom !== null) {
          this.expandedSettings.dashboard.dom.style.width = this.expandedSettings.dashboard.defaultWidth
        }
        if (this.getInterfaceEditorVersion() === 2) {
          this.getCard().showButtonsBlock()
          if (this.expandedSettings.v2.container) {
            this.expandedSettings.v2.container.style.cssText
              = this.expandedSettings.v2.defaultContainerCssText
          }
        }
      }
      let me = this
      me.$nextTick(() => {
        setTimeout(() => MapManager.updateSize(me.map), 0)
        me.setComponentSize()
      })
    },
    highlightFeatures (features) {
      features.forEach((feature) => { MapManager.highlightFeature(feature) })
    },
    unhighlightFeatures (features) {
      features.forEach((feature) => { MapManager.unhighlightFeature(feature) })
    },
    getSelectedFeatures () {
      return this.activeFeatures
    },
    print () {
      MapManager.export(this.map)
    },
    changeFooterTargetCS (newVal) {
      this.$set(this.footerData, 'targetCS', newVal)
      this.loadAndRegisterCS(newVal)
      this.applyShowMouseCoordinatesInteractive(this.showMouseCoordinates)
    },
    saveGeometry (item, geom, layerSettings) {
      if (layerSettings.source.type === 'Field') {
        this.getModel()[layerSettings.source.geometryField] = this.getActiveLayerFeatures()
        this.showSuccess('геометрия обновлена')
        return
      }

      let geometry = MapManager.getGeometryAsText(geom, 'GeoJSON', layerSettings.source.nativeCoordinateSystemId)
      //let id = item.openCard.properties['attr_' + layerSettings.interactive.card.fieldId + '_']
      let id = item.openCard.properties['id']

      let formData = new FormData()
      // formData.append('card_id', layerSettings.interactive.card.card.cardId)
      formData.append('id', id)
      formData.append('guid', '"' + item.openCard.properties.guid + '"')
      formData.append(
        `attr_${layerSettings.source.geometryField}_`,
        '"' + JSON.stringify(JSON.parse(geometry).geometry).replaceAll('"', '\\"') + '"'
      )

      this.$http.put(
        `${this.$config.api}/registryservice/registry/${layerSettings.source.entityId}/records/${id}`,
        formData,
        {
          hideNotification: true,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then(() => {
          this.showSuccess('геометрия обновлена')
        })
        .catch(() => {
          this.showError('обновление геометрии не произошло')
        })

    }
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 99;
  position: relative;
}
.mapCollapsed {
  width: 100%;
  height: 45%;
  z-index: 99;
}
.map-container {
  width: 100%;
  height: 100%;
}
.tools-panel, .layers {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 999;
  height: calc(100% - 26px);
}
.zoom {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 999;
}
.interactive-panel {
  position: absolute;
  right: 10px;
  top: 65px;
  z-index: 999;
  width: 300px;
  height: calc(100% - 65px - 108px);
  background: white;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.streetViewContainerCollapsed {
  width: 100%;
  height: 0;
}
.streetViewContainer {
  width: 100%;
  height: 55%;
}
</style>
<style>
  .enter-address-message-box {
    width: auto;
    max-width: 420px;
  }
  .create-geometry-by-vertices-msgbox {
    width: auto;
  }
</style>
