<template>
  <div class="tools-container">
    <div :class="`tools-${mobileMode ? 'br' : toolsPosition}`" v-if="!mobileMode || (mobileMode && !collapsed)">
      <div
        :class="['panel', mobileMode ? 'mobile-panel' : null]"
        :style="mobileMode ? 'background: none' : null">
        <div
          :class="['tools-buttons', mobileMode ? 'mobile-tools-buttons' : null]"
          style="display: flex; flex-wrap: wrap;"
          :style="flexToolsButtonsStyle"
        >
          <el-tooltip
            v-for="tool in visibleTools"
            :key="tool.id"
            :content="$locale.interface_editor.component_editor.map_tools[tool.id]"
            :placement="mobileMode ? 'left' : 'top'"
            :disabled="mobileMode || !!tool.component">
            <template v-if="tool.options && tool.options.length > 0">
              <el-dropdown size="medium" @command="commandHandler($event, tool)" trigger="click" :class="['button', mobileMode ? 'tool-button-mobile' : 'tool-button', activated.includes(tool.id) ? 'activated' : null]">
                <div><font-awesome-icon :icon="tool.icon"/></div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in tool.options"
                    :key="item.id"
                    :command="item.id"
                    :divided="item.divided"
                    :disabled="typeof item.disabled === 'function' ? item.disabled(checkMarks, item) : false"
                    v-if="typeof item.hide === 'function' ? !item.hide(checkMarks, item, tool) : true"
                    :icon="(item.checked !== undefined && item.checked) ? 'el-icon-check' : ''">
                    {{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <div
              v-else-if="!tool.component"
              :class="['button', mobileMode ? 'tool-button-mobile' : 'tool-button']"
              @click="$emit(tool.id)">
              <font-awesome-icon :icon="tool.active ? tool.activeIcon : tool.icon"/>
            </div>
            <component
              v-else
              :is="tool.component"
              :value="tool.properties"
              :style="`width: ${45 * tool.width}px; display: flex; align-items: center; height: 45px;`"
              class="componentTool"
              @doComponentAction="$emit(tool.id, $event)"
            >
            </component>
          </el-tooltip>
        </div>
        <div class="angle-button button" v-if="mobileMode" @click="collapsed = !collapsed">
          <font-awesome-icon icon="angle-down"/>
        </div>
      </div>
    </div>
    <div class="tools-br" v-if="mobileMode && collapsed">
      <div class="button tool-button expand-button with-radius" @click="collapsed = !collapsed">
        <font-awesome-icon icon="ellipsis-v"/>
      </div>
    </div>
    <div class="tools-bl" v-if="mobileMode" style="z-index: 998">
      <div class="button tool-button with-radius" @click="$emit('layers')">
        <font-awesome-icon icon="layer-group"/>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport, faAngleDown, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import SearchLongField from './SearchLongField'

library.add(faLayerGroup, faSearch, faRulerCombined, faPen, faGlobe, faExpandArrowsAlt, faCompressArrowsAlt, faMapMarkerAlt, faTrashAlt, faVectorSquare, faCamera, faFileImport, faFileExport, faAngleDown, faEllipsisV)
export default {
  name: 'tools',
  components: {
    FontAwesomeIcon,
    SearchLongField
  },
  props: {
    settings: {
      type: Object,
      default: () => {
        return {}
      }
    },
    mobileMode: {
      type: Boolean,
      default: false
    },
    toolsPosition: {
      type: String,
      default: 'tr'
    },
    checkMarks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    activated: {
      type: Array,
      default: () => {
        return []
      }
    },
    componentSize: {
      type: Object,
      default: () => {
        return { height: 0, width: 0 }
      }
    }
  },
  data () {
    return {
      collapsed: true,
      tools: [
        { id: 'layers', hidden: false, icon: 'layer-group', index: 1 },
        { id: 'search', hidden: false, icon: 'search', index: 2 },
        { id: 'change-selection-type',
          hidden: false,
          icon: 'vector-square',
          index: 3,
          options: [
            { id: 'singleclick', name: 'точкой', checked: true },
            { id: 'rectangle', name: 'прямоугольником' },
            { id: 'polygon', name: 'многоугольником' },
            { id: 'intersection', name: 'поиск пересечений' },
            { id: 'rosreestr', name: 'сведения Росреестра' }
          ]
        },
        { id: 'measurement',
          hidden: false,
          icon: 'ruler-combined',
          index: 4,
          options: [
            { id: 'distance', name: 'Дистанция' },
            { id: 'area', name: 'Площадь' },
            { id: 'reset', name: 'Очистить' }
          ]
        },
        {
          id: 'position-on',
          hidden: false,
          icon: 'map-marker-alt',
          index: 5,
          options: [
            { id: 'coordinates', name: 'Координаты' },
            { id: 'address', name: 'Адрес' },
            {
              id: 'defineAddress',
              name: 'Определение адреса',
              hide: (marks, item, tool) => {
                if (tool.properties && tool.properties.isDefineAddressEnable) {
                  return false
                }
                return true
              }
            }
          ],
          properties: {}
        }, {
          id: 'drawing-geometry',
          hidden: false,
          icon: 'pen',
          index: 6,
          options: [
            { id: 'Point', name: 'Точка' },
            { id: 'LineString', name: 'Линия' },
            { id: 'Polygon', name: 'Полигон' },
            { id: 'Vertices', name: 'По координатам' },
            { id: 'EditByVertices', name: 'Редактирование по вершинам' },
            {
              id: 'Snap',
              name: 'Привязка к вершинам',
              divided: true,
              disabled: (marks, item) => {
                if (!Array.isArray(marks['drawing-geometry'])) {
                  return true
                }
                let b = ['Point', 'LineString', 'Polygon']
                for (let i = 0; i < b.length; i++) {
                  if (marks['drawing-geometry'].includes(b[i])) {
                    return false
                  }
                }
                return true
              },
              hide: (marks, item, tool) => {
                if (tool.properties && tool.properties.snap && tool.properties.snap.enable) {
                  return false
                }
                return true
              }
            }
          ],
          properties: {}
        },
        { id: 'delete-features', hidden: false, icon: 'trash-alt', index: 7 },
        { id: 'google-street-view', hidden: false, icon: 'globe', index: 8 },
        { id: 'print', hidden: false, icon: 'camera', index: 9 },
        {
          id: 'import-features',
          hidden: false,
          icon: 'file-import',
          index: 10,
          options: [
            { id: 'file-dxf', name: 'Файл' },
            { id: 'string-wkt', name: 'Строка wkt' }
          ]
        },
        {
          id: 'export-features',
          hidden: false,
          icon: 'file-export',
          index: 10,
          options: [
            { id: 'png', name: 'В png' },
            { id: 'geoTiff', name: 'В geoTiff' }
          ]
        },
        { id: 'expand', hidden: false, icon: 'expand-arrows-alt', index: 12, active: false, activeIcon: 'compress-arrows-alt' },
        { id: 'search-component', hidden: true, icon: 'pen', index: 13, component: 'SearchLongField', width: 3, properties: {} }
      ],
      flexToolsButtonsStyle: ''
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    visibleTools: function () {
      return this.tools.filter(item => !item.hidden && !(this.mobileMode && item.id === 'layers'))
    }
  },
  watch: {
    settings: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    checkMarks: {
      handler: function (newValue) {
        let marks = Object.entries(this.checkMarks)
        for (let i = 0; i < marks.length; i++) {
          let toolIndex = this.tools.findIndex(item => item.id === marks[i][0])
          for (let j = 0; j < this.tools[toolIndex].options.length; j++) {
            this.$set(this.tools[toolIndex].options[j], 'checked', marks[i][1].includes(this.tools[toolIndex].options[j].id))
          }
        }
      },
      deep: true
    },
    componentSize: {
      handler: function (newValue) {
        let height = 200
        let width = 200
        // не компоненты
        let tt = this.tools.map((item) => (!item.hidden && !item.component) ? 1 : 0 ).reduce((p, c) => p + c)
        // компоненты
        let tt2 = this.tools.map((item) => {
          let result = 0
          if (!item.hidden) {
            if (item.component) {
              result = item.width
            }
          }
          return result
        }).reduce((p, c) => p + c)
        let countTools = tt + tt2
        countTools -= this.mobileMode ?  1 : 0
        if (this.mobileMode) {
          height = newValue.height - 56 - (newValue.height - 56) % 45
          if (height / 45 > countTools) {
            height = countTools * 45
          }
          width = Math.ceil(countTools / (height / 45)) * 45
        } else {
          let tmpW = newValue.width - (newValue.toolsPanelWidth + 75)
          width = tmpW - tmpW % 45
          if (width / 45 > countTools) {
            width = countTools * 45
          }
          if (width === 0) {
            width = 45
          }
          height = Math.ceil(countTools / (width / 45)) * 45
        }
        this.flexToolsButtonsStyle = 'height: ' + height + 'px; width: ' + width + 'px;'
      },
      deep: true
    }
  },
  methods: {
    init () {
      this.tools.forEach((item) => {
        if (this.settings[item.id]) {
          this.$set(item, 'hidden', this.settings[item.id].hidden)
          this.$set(item, 'index', this.settings[item.id].index)
          if (this.settings[item.id].properties) {
            this.$set(item, 'properties', this.settings[item.id].properties)
          }
          if (this.settings[item.id].width) {
            this.$set(item, 'width', this.settings[item.id].width)
          }
        }
      })
      this.tools = this.tools.sort((a, b) => {
        return a.index - b.index
      })
    },
    changeActive (id) {
      this.tools.forEach((item) => {
        if (item.id === id) {
          this.$set(item, 'active', !item.active)
        }
      })
    },
    commandHandler (event, tool) {
      this.$emit(tool.id, event, tool.properties)
    }
  }
}
</script>

<style scoped>
  .componentTool1 {
    display: inline-block;
    top: 0px;
    height: 45px;
    position: absolute;
  }
  .panel-with-angle {

  }
  .tools-container {
    width: 100%;
    height: 100%;
    display: contents;
  }
  .panel {
    border-radius: 6px;
    box-shadow: none;
  }
  .with-radius {
    border-radius: 6px;
  }
  .tools-buttons {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    overflow: hidden;
    background-color: white;
    /*display: flex;*/
    flex-direction: row;
  }
  .mobile-tools-buttons {
    flex-direction: column;
    flex-wrap: wrap-reverse;
    position: relative;
    bottom: 10px;
    display: flex;
  }
  .mobile-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .tools-tr {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
  }
  .tools-tl {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 999;
  }
  .tools-br {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 999;
  }
  .tools-bl {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 999;
  }
  .button {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: white;
    cursor: pointer;
  }
  .button svg {
    color: #6E7B87;
    font-size: 16px;
    padding: 14px;
  }
  .button:hover {
    background-color: #ecf5ff;
  }
  .button:hover svg {
    color: #409EFF;
  }
  .activated {
    background-color: #ecf5ff;
  }
  .activated svg {
    color: #409EFF;
  }
  .angle-button {
    width: 44px;
    height: 36px;
    border-radius: 6px;
    position: relative;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
  }
  .angle-button svg {
    padding: 10px 17px;
  }
  .expand-button svg {
    padding: 13px 20px;
  }
</style>
