import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest';
import { HTTPMethod } from '@/core/infrastructure/api/APIClient';

export namespace RosreestrAPI {
  export class GetBuildingByCoordinates implements APIRequest<string> {
    response: string;
    path = '/api/features/5';
    baseURL = 'https://pkk.rosreestr.ru';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(point: Array<Number>) {
      this.path += `?sq={"type":"Point","coordinates":[${point[0]}, ${point[1]}]}&tolerance=1&limit=11`
    }
  }

  export class GetAreaByCoordinates implements APIRequest<string> {
    response: string;
    path = '/api/features/1';
    baseURL = 'https://pkk.rosreestr.ru';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(point: Array<Number>) {
      this.path += `?sq={"type":"Point","coordinates":[${point[0]}, ${point[1]}]}&tolerance=1&limit=11`
    }
  }

  export class GetBuildingInfoByCadastrNumber implements APIRequest<string> {
    response: string;
    path = '/api/features/5';
    baseURL = 'https://pkk.rosreestr.ru';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(cadastrNnmber: string) {
      this.path += `/${cadastrNnmber}`
    }
  }

  export class GetAreaInfoByCadastrNumber implements APIRequest<string> {
    response: string;
    path = '/api/features/1';
    baseURL = 'https://pkk.rosreestr.ru';
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data;
    hideNotification = true;

    constructor(cadastrNnmber: string) {
      this.path += `/${cadastrNnmber}`
    }
  }
}
